<template>
  <div :class="[$style.wrapper]">
    <ws-icon
      v-if="!icon && error"
      xxl
      color="white"
      :class="$style.wrapper__fallbackImg"
      class="absolute-center"
    >
      image
    </ws-icon>

    <lazy-img
      isBackground
      :src="src"
      :cloudflare-options="{ width: 200, quality: 70 }"
      :class="[
        $style.img,
        {
          ['flex flex-center']: icon,
        },
      ]"
    >
      <ws-icon v-if="icon" color="white" xxl>{{ icon }}</ws-icon>
    </lazy-img>

    <lazy-img v-if="!icon" v-show="false" :src="src" alt="Broken test" @error="error = true" />
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import LazyImg from "@/components/base/LazyImg";

/**
 * Превью слайда
 *
 * @vue-prop {string} [src=""] - url изображения
 * @vue-prop {string} [type="img"] - тип слайда
 * @vue-computed {object} typeToIcon - содержит имя иконки по типу слайда
 * @vue-computed {string|null} icon - иконка вычисленная от типа слайда
 * @vue-data {boolean} - является ли изображение битым
 */
export default {
  name: "SpeakerPresentationSlidePreview",
  components: {
    WsIcon,
    LazyImg,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: SLIDE_TYPE.img,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    typeToIcon() {
      return {
        [SLIDE_TYPE.video]: "video",
        [SLIDE_TYPE.test]: "list-ul",
        [SLIDE_TYPE.timer]: "clock",
        [SLIDE_TYPE.results]: "ballot",
      };
    },
    icon() {
      return this.typeToIcon[this.type] || null;
    },
  },
  watch: {
    src() {
      if (!this.icon) {
        this.error = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba($gray, 0.6);
  border-radius: 5px;

  &__fallbackImg {
    z-index: 1;
  }
}

.img {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
}
</style>
