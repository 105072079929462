<template>
  <div :class="$style.wrapper">
    <h2 v-line-clamp="1" :title="name" :class="$style.name" class="text-lg semi-bold">
      <ws-icon v-if="active" :class="$style.name__icon" duotone color="primary" sm>circle</ws-icon>
      {{ name }}
    </h2>
    <span :class="$style.counter" class="text-sm">
      {{ $tc("plurals.slide", slides.length) }}
    </span>
    <ws-button
      :class="$style.launchBtn"
      color="primary"
      rounded
      :outlined="active"
      :disabled="blocked"
      @click="launchBtnClickHandler"
    >
      <loader
        v-if="pending"
        :main-stroke-color="!active ? '#fff' : ''"
        :class="$style.launchBtn__loader"
      />
      {{ active ? $t("SpeakerPresentation.control") : $t("SpeakerPresentation.run") }}
    </ws-button>
    <div
      ref="slides"
      :class="$style.list"
      @mousedown.prevent="startScroll"
      @mouseup.prevent="stopScroll"
      @mousemove.prevent="moveSlider"
      @mouseout="stopScrollMouseOut"
    >
      <speaker-presentation-slide-preview
        v-for="slide in slides"
        :key="slide.id"
        :src="slide.src"
        :type="slide.type"
      />
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import Loader from "@/components/common/elements/Loader";
import SpeakerPresentationSlidePreview from "@/components/speaker/presentation/SpeakerPresentationSlidePreview";
import slidesHorizontalScrollMixin from "@/components/common/slides/mixins/slidesHorizontalScrollMixin";

/**
 * Элемент списка презентаций для интрефейса спикера
 *
 * @vue-prop {Array} [slides=[]] - слайды для отображения превью
 * @vue-prop {boolean} [active=false] - запущенна текущая презентация или нет
 * @vue-prop {boolean} [blocked=false] - блокируем кнопку запуска если запуск другой презентации в процессе
 * @vue-prop {string} [name=""] - имя презентации
 * @vue-data {boolean} [pending=false] - текущая презентация в процессе запуска и перехода к управлению
 * @vue-event launch - событие клика на кнопку запуска или управления презентацией для перехода к управлению
 */
export default {
  name: "SpeakerPresentationsItem",
  components: {
    WsButton,
    WsIcon,
    Loader,
    SpeakerPresentationSlidePreview,
  },
  mixins: [slidesHorizontalScrollMixin],
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  watch: {
    blocked(val) {
      if (!val && this.pending) {
        this.pending = false;
      }
    },
  },
  methods: {
    launchBtnClickHandler() {
      this.pending = true;
      this.$emit("launch");
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: grid;
  grid-template-areas:
    "name btn"
    "counter btn"
    "list list";
  grid-template-columns: 1fr max-content;
  overflow: hidden;
}

.name {
  grid-area: name;
  padding-right: 10px;
  color: $default-text-color;

  &__icon {
    margin-right: 5px;
  }
}

.counter {
  grid-area: counter;
  padding-top: 5px;
  color: $gray-darken;
}

.launchBtn {
  grid-area: btn;
  align-self: start;

  &__loader[class*="throbber"] {
    width: 20px;
    height: 20px;
  }
}

.list {
  display: grid;
  grid-area: list;
  grid-template-rows: 90px;
  grid-auto-columns: 120px;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-top: 20px;
  overflow-x: auto;

  @include reset-scroll;
}
</style>
