<template>
  <header
    class="flex justify-between align-center"
    :class="[
      $style.header,
      {
        [$style.header_sort]: sortAvailable,
      },
    ]"
  >
    <h1 :class="$style.title">{{ $t("common.allPresentation") }}</h1>
    <div v-if="sortAvailable" :class="$style.sort">
      <ws-button data-testid="sortByName" font-size="lg" @click="sortClickHandler('name')">
        {{ $t("common.sortByName") }}
        <ws-icon md color="primary">{{
          sortDescByName ? "sort-amount-down" : "sort-amount-up"
        }}</ws-icon>
      </ws-button>
      <ws-button data-testid="sortByDate" font-size="lg" @click="sortClickHandler('id')">
        {{ $t("common.sortByDate") }}
        <ws-icon md color="primary">{{
          sortDescByDate ? "sort-amount-down" : "sort-amount-up"
        }}</ws-icon>
      </ws-button>
    </div>
  </header>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
/**
 * Хедер для страницы списка презентаций
 *
 * @vue-prop {boolean} sortAvailable - доступен ли модуль сортировки
 * @vue-data {boolean} desc - включена ли сортировка по убыванию
 * @vue-data {string} field - ключ по которому нужно выполнить сортировку
 * @vue-computed {boolean} sortDescByName - сортируем по убыванию и по имени
 * @vue-computed {boolean} sortDescByDate - сортируем по убыванию и по дате
 * @vue-event {object} sort - события для выполнения сортировки
 */
export default {
  name: "SpeakerPresentationsHeader",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    sortAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      desc: false,
      field: "id",
    };
  },
  computed: {
    sortDescByName() {
      return this.field === "name" && this.desc;
    },
    sortDescByDate() {
      return this.field === "id" && this.desc;
    },
  },
  methods: {
    /**
     * Эмитит событие с ключом поля по которому нужно отсортировать список и направление сортировки
     *
     * @param {string} field - ключ по которому нужно выполнить сортировку
     */
    sortClickHandler(field) {
      this.desc = this.field === field ? !this.desc : true;
      if (this.field !== field) {
        this.field = field;
      }
      this.$emit("sort", {
        field,
        desc: this.desc,
      });
    },
  },
};
</script>

<style lang="scss" module>
.header {
  padding: 20px 0;
  border-bottom: 1px solid $gray-light;

  @include mq-tablet {
    margin: 0 0 40px;
  }
}

.title {
  padding-left: 10px;
  color: $default-text-color;

  @include mq-tablet {
    padding-left: 0;
  }
}

.header_sort .title {
  display: none;

  @include mq-tablet {
    display: block;
  }
}

.sort {
  color: $default-text-color;

  @include mq-to-tablet {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
