<template>
  <div :class="$style.container">
    <speaker-presentations-header
      :sort-available="availableSortPresentations"
      @sort="sortHandler"
    />
    <div :class="$style.presentations">
      <p
        v-if="!sortedPresentations.length"
        :class="$style.presentations__empty"
        class="absolute-center"
      >
        {{ $t("messages.common.list_empty") }}
      </p>
      <div v-else :class="$style.presentations__wrapper">
        <speaker-presentations-item
          v-for="presentation in sortedPresentations"
          :key="presentation.id"
          :class="$style.presentations__item"
          :name="presentation.name"
          :active="currentPresentationInfo.id === presentation.id"
          :slides="presentation.slides"
          :blocked="blocked"
          @launch="launchPresentation(presentation.id)"
        />
      </div>
    </div>
    <container-test-and-polls-panel
      v-if="hasActivePoll"
      :icon="false"
      :class="$style.testsAndPollsPanel"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { presentation, room, speaker, speakerPresentation } from "@/store/modules/store.namespaces";
import { ACTIVE_POLL_ID, ACTIVE_RESULTS_ID } from "@/store/modules/common/getter-types";
import { GET_SPEAKER_STATE } from "@/store/modules/speaker/action-types";
import { CHANGE_PRESENTATION } from "@/store/modules/presentation/action-types";
import {
  AVAILABLE_SORT_PRESENTATIONS,
  PRESENTATIONS,
} from "@/store/modules/speaker/modules/presentation/getter-types";

import SpeakerPresentationsHeader from "@/components/speaker/presentation/SpeakerPresentationsHeader";
import SpeakerPresentationsItem from "@/components/speaker/presentation/SpeakerPresentationsItem";
import ContainerTestAndPollsPanel from "@/containers/speaker/ContainerTestAndPollsPanel";
import { SpeakerRouteName } from "@/constants/router/router-const";

/**
 * Представление для страницы презентаций
 *
 * @vue-data {boolean} [isLoading=true] - страница в процессе загрузки
 * @vue-data {boolean} [blocked=false] - кнопки запуска и управления заблокированны
 * @vue-data {boolean} [desc=false] - текущая сортировка по убыванию?
 * @vue-data {string} [sortField="id"] - ключ по которому нужно выполнить сортировку
 * @vue-computed {Array} sortedPresentation - отсортированный список презентаций
 * @vue-computed {boolean} hasActivePoll - запущен опрос, или показ результатов?
 */
export default {
  name: "ViewPresentations",
  components: {
    SpeakerPresentationsHeader,
    SpeakerPresentationsItem,
    ContainerTestAndPollsPanel,
  },
  data() {
    return {
      blocked: false,
      sortField: "id",
      desc: false,
    };
  },
  computed: {
    ...mapState(presentation, ["currentPresentationInfo"]),
    ...mapGetters(room, {
      activePollId: ACTIVE_POLL_ID,
      activeResultsId: ACTIVE_RESULTS_ID,
    }),
    ...mapGetters(speakerPresentation, {
      presentations: PRESENTATIONS,
      availableSortPresentations: AVAILABLE_SORT_PRESENTATIONS,
    }),
    hasActivePoll() {
      return !!this.activePollId || !!this.activeResultsId;
    },
    sortedPresentations() {
      if (!this.availableSortPresentations) {
        return this.presentations;
      }
      return [...this.presentations].sort(this.sortPresentationsByField);
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(presentation, {
      startPresentation: CHANGE_PRESENTATION,
    }),
    ...mapActions(speaker, {
      getSpeakerState: GET_SPEAKER_STATE,
    }),
    /**
     * Инициализация страницы
     */
    init() {
      if (this.availableSortPresentations) {
        const sortState = localStorage.getItem("presentationSortState");
        if (sortState) {
          const { field, desc } = JSON.parse(sortState);
          this.sortField = field;
          this.desc = desc;
        }
      }
    },
    /**
     * Запуск презентации
     *
     * @param {Presentation} presentation - Презентация которую нужно запустить
     */
    async launchPresentation(id) {
      try {
        this.blocked = true;
        await this.getSpeakerState(this.$route.params.id);
        if (id !== this.currentPresentationInfo.id) {
          await this.startPresentation(id);
        }
        this.goToPresentation(id);
      } catch {
        this.blocked = false;
        this.$notify({
          group: "common",
          type: "error",
          title: this.$t("common.error"),
          text: this.$t("Presentation.startError"),
        });
      }
    },
    /**
     * Переход к управлению презентацией
     *
     * @param {string} id - ид презентации для перехода
     */
    goToPresentation(id) {
      this.$router.push({ name: SpeakerRouteName.Presentation, params: { presId: id } });
    },
    /**
     * Обработчик события клика на кнопку сортировки<br>
     * Сохраняет состояние в localStorage
     *
     * @param {object} event - событие
     * @param {string} event.field - поле по которому нужно провести сортировку
     * @param {boolean} event.desc - сортировка по убыванию?
     */
    sortHandler({ field, desc }) {
      this.sortField = field;
      this.desc = desc;
      localStorage.setItem("presentationSortState", JSON.stringify({ field, desc }));
    },
    /**
     * Сортируем презентации по указаному полю
     *
     * @param {object} presA - презентация
     * @param {object} presB - презентация
     * @returns {number}
     */
    sortPresentationsByField(presA, presB) {
      const fieldValueA = this.sortField === "name" ? presA.name.toLowerCase() : presA.id;
      const fieldValueB = this.sortField === "name" ? presB.name.toLowerCase() : presB.id;
      if (fieldValueA > fieldValueB) {
        return this.desc ? -1 : 1;
      }
      if (fieldValueA < fieldValueB) {
        return this.desc ? 1 : -1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-rows: auto 1fr;

  @include mq-tablet {
    padding: 0 40px;
  }
}

.presentations {
  position: relative;
  padding: 10px 15px 0;
  overflow: auto;

  @include reset-scroll;

  @include mq-tablet {
    padding: 0;
  }

  &__item {
    margin-bottom: 25px;
  }

  &__empty {
    font-size: 24px;
    color: $default-text-color;
  }

  &__wrapper {
    padding-bottom: 15px;
  }
}

.testsAndPollsPanel {
  position: absolute;
  right: 18px;
  bottom: 18px;
  z-index: 10;

  @include mq-tablet {
    right: 80px;
    bottom: 80px;
  }
}
</style>
